import React from 'react';
import {
  FaDribbbleSquare,
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaTwitterSquare,
} from 'react-icons/fa';
import { FaFacebook, FaTelegram, FaXTwitter } from "react-icons/fa6";
import logo from '../assets/miexs_logo.svg';

const Footer = () => {
  return (
    <div className="max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300">
      <div>
        {/* <h1 className='w-full text-3xl font-bold text-[#00df9a]'>BULKWALLET.</h1> */}
        <img src={logo} alt="/" />
        <p className="py-4">MIEXS Chain network is a permissionless and border-less publish ledger designed for next generation web3 gaming..</p>
        <div className="flex gap-6 my-6">
          <a href="https://www.facebook.com/VinDAXOfficial/" target="_blank">
            <FaFacebook size={30} />
          </a>
          {/* <FaInstagram size={30} /> */}
          <a href="https://twitter.com/VinDAXOfficial" target="_blank">
            <FaXTwitter size={30} />
          </a>
          <a href="https://t.me/VinDAX_Exchange" target="_blank">
            <FaTelegram size={30} />
          </a>
          {/* <FaGithubSquare size={30} /> */}
          {/* <FaDribbbleSquare size={30} /> */}
        </div>
      </div>
      <div className="lg:col-span-2 flex justify-between mt-6">
        <div>
          <h6 className="font-medium text-gray-400">Solutions</h6>
          <ul>
            <li className="py-2 text-sm">Analytics</li>
            <li className="py-2 text-sm">Marketing</li>
            <li className="py-2 text-sm">Commerce</li>
            <li className="py-2 text-sm">Insights</li>
          </ul>
        </div>
        <div>
          <h6 className="font-medium text-gray-400">Support</h6>
          <ul>
            <li className="py-2 text-sm">Pricing</li>
            <li className="py-2 text-sm">Documentation</li>
            <li className="py-2 text-sm">Guides</li>
            <li className="py-2 text-sm">API Status</li>
          </ul>
        </div>
        <div>
          <h6 className="font-medium text-gray-400">Company</h6>
          <ul>
            <li className="py-2 text-sm">About</li>
            <li className="py-2 text-sm">Blog</li>
            <li className="py-2 text-sm">Jobs</li>
            <li className="py-2 text-sm">Press</li>
            <li className="py-2 text-sm">Careers</li>
          </ul>
        </div>
        <div>
          <h6 className="font-medium text-gray-400">Legal</h6>
          <ul>
            <li className="py-2 text-sm">Claim</li>
            <li className="py-2 text-sm">Policy</li>
            <li className="py-2 text-sm">Terms</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
