import React, { useRef, useState } from 'react';
import { ethers } from 'ethers';
import { AiFillWarning } from 'react-icons/ai';

const Cards = () => {

    const [copySuccess, setCopySuccess] = useState('');
    const textAreaRef = useRef(null);
    const [textAreaRefFullScreen, setTextAreaRefFullScreen] = useState("");
    const [numWallets, setNumWallets] = useState(0);
    const [senderAmount, setSenderAmount] = useState(0);
    const [rowPerWallet, setRowPerWallet] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [showFullScreen, setshowFullScreen] = useState(false);
    const [warnStrings, setWarnStrings] = useState("");
    const [walletLists, setWalletLists] = useState([]);
    const [downloadLink, setDownloadLink] = useState('');
    const [downloadLinkWallet, setDownloadLinkWallet] = useState('');

    const copyToClipboard = (e) => {
        textAreaRef.current.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the whole text area selected.
        e.target.focus();
        setCopySuccess('Copied!');
    };

    const handleTextareaChange = (e) => {
        setCopySuccess(null); // Set copySuccess to null when textarea value changes
    };

    //Create wallet 
    const handleGenerateWallets = (e) => {
        // setNumWallets(0);
        // if (numWallets < 1) {
        //     setWarnStrings("Number of Wallets to Generate Is Empty.")
        //     setShowModal(true);
        //     return
        // }
        setDownloadLinkWallet('');
        e.preventDefault();
        const wallets = generateWallets(numWallets);
        // const walletString = JSON.stringify(wallets, null, 2);
        // const titleString = 'public_address, private_key';
        // const walletString = wallets.map((wallet) => `${wallet.public_address}, ${wallet.private_key}`).join('\n');
        textAreaRef.current.value = formatWallets(wallets);
        setDownloadLink('');
    };


    const generateWallets = (num) => {
        // Implement your wallet generation logic here
        // This is just a placeholder example that generates random wallet addresses
        const wallets = [];
        // Generating the specified number of wallets
        for (let i = 0; i < num; i++) {
            // Generating the wallet
            const wallet = ethers.Wallet.createRandom()

            // Generating the private key
            const privateKey = wallet.privateKey;
            // Generating the address from private key
            // console.log('WALLET', wallet.address)
            const publicKey = wallet.address;

            // Adding the generated wallet to the array
            wallets.push({
                private_key: privateKey,
                public_address: publicKey
            });
        }

        // console.log(`Generated ${num} wallets`);
        setWalletLists(wallets);
        return wallets;
    };

    const formatWallets = (wallets) => {
        let walletString = `${wallets.length} addresses generated\n\n`;
        walletString += 'Public key,Private key\n';
        wallets.forEach((wallet, index) => {
            walletString += `${wallet.public_address},${wallet.private_key}`;
            if (index < wallets.length - 1) {
                walletString += '\n';
            }
        });

        const data = new Blob([walletString], { type: 'text/plain' })

        // this part avoids memory leaks
        if (downloadLinkWallet !== '') window.URL.revokeObjectURL(downloadLinkWallet)

        // update the download link state
        setDownloadLinkWallet(window.URL.createObjectURL(data))

        return walletString;
    };

    const handleNumWalletsChange = (e) => {
        const num = parseInt(e.target.value);
        setNumWallets(num);
    };

    const handleAmountSenderChange = (e) => {
        const num = e.target.value;
        setSenderAmount(num);
    };

    const handleRowPerWalletsChange = (e) => {
        const num = parseInt(e.target.value);
        setRowPerWallet(num);
    };

    const formatListTransaction = () => {
        let sum = senderAmount
        const numberTransaction = numWallets * rowPerWallet
        const interval = senderAmount/numberTransaction
        const min = interval - 0.1 * interval
        const max = interval + 0.1 * interval
        // console.log('numberTransaction', numberTransaction);
        const number = []
        for (let i = 0; i < (numberTransaction - 1); i++) {
            const randomnumber = Math.random() * (max - min) + min;
            sum -= randomnumber < 0 ? 0.001 : randomnumber
            number.push(randomnumber < 0 ? 1 : randomnumber.toFixed(18).replace(/\.?0+$/, ""))
        }
        // console.log("summmm:  ", sum);
        number.push(Number(sum).toFixed(18).replace(/\.?0+$/, ""))
        // console.log(number, number.reduce((a, b) => Number(a) + Number(b), 0))
        var r = 0;
        let walletString = "";
        walletLists.forEach((wallet, index) => {
            for (let i = 0; i < rowPerWallet; i++) {
                walletString += ` ${wallet.public_address}, ${number[r]} `
                walletString += '\n'
                r++
            }
        })
        // console.log(walletString)
        return walletString;
    }
    const saveFile = (result) => {
        // const fileData = JSON.stringify(result);
        const data = new Blob([result], { type: 'text/plain' })

        // this part avoids memory leaks
        if (downloadLink !== '') window.URL.revokeObjectURL(downloadLink)

        // update the download link state
        setDownloadLink(window.URL.createObjectURL(data))
    }
    const handleFullScreen = (e) => {
        if (showFullScreen) {
            setshowFullScreen(false);
        } else {
            console.log("formatWallets(walletLists)", formatWallets(walletLists));
            setTextAreaRefFullScreen(formatWallets(walletLists));
            setshowFullScreen(true);
        }
    }

    const handleCreateSenderInputFile = (e) => {
        e.preventDefault();
        // console.log('senderAmount', senderAmount);
        // console.log('Number Row per wallet', rowPerWallet);
        if (numWallets < 1) {
            setWarnStrings("Number of Wallets to Generate Is Empty.")
            setShowModal(true);
            return
        }
        if (walletLists.length === 0) {
            setWarnStrings("Don't have any wallet!")
            setShowModal(true);
            return
        }
        if (senderAmount <= 0) {
            setWarnStrings("Sender Amount must be greater than 0 !!!")
            setShowModal(true);
            return
        }
        if (rowPerWallet < 1) {
            setWarnStrings("Transaction rows per wallet must be greater than 1 !!!")
            setShowModal(true);
            return
        }
        const listAmount = formatListTransaction();
        saveFile(listAmount);
    };

    return (
        <div id="cardsSection" className="flex flex-col items-center justify-center bg-white ">

            <form className="bg-white px-8 py-6 max-w-5xl w-full mt-20 mb-20">
                <div className="mb-4">
                    <label className="block text-gray-700 font-bold mb-2" htmlFor="network">
                        Network
                    </label>
                    <select
                        className="border border-gray-300 rounded px-4 py-2 w-full"
                        id="network"
                        name="network"
                    >
                        <option value="eth">MIX</option>
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-bold mb-2" htmlFor="numWallets">
                        Number of Wallets to Generate
                    </label>
                    <input
                        className="border border-gray-300 rounded px-4 py-2 w-full"
                        type="number"
                        id="numWallets"
                        name="numWallets"
                        placeholder="Enter number"
                        onChange={handleNumWalletsChange}
                    />
                </div>
                <div className="flex justify-between">
                    <button
                        className="bg-[#000000] hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                        type="submit"
                        onClick={handleGenerateWallets}
                    >
                        Create
                    </button>
                </div>
                <div className="mt-4">
                    <label className="block text-gray-700 font-bold mb-2" htmlFor="result">
                        Result
                    </label>
                    <div className="w-full h-full mb-4 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
                        <div className="flex items-center justify-between px-3 py-2 border-b dark:border-gray-600">
                            <div className="flex flex-wrap items-center divide-gray-200 sm:divide-x dark:divide-gray-600">
                                <div className="flex flex-wrap items-center space-x-1">
                                    <button onClick={copyToClipboard} type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 512 512"><path d="M272 0H396.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H272c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48zM48 128H192v64H64V448H256V416h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48z" /></svg>
                                        <span className="sr-only">Copy</span>
                                    </button>
                                    {copySuccess}
                                    <a href={downloadLinkWallet} download="List_Generate_Wallets.txt">
                                        <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            <span className="sr-only">Download</span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                            <button onClick={handleFullScreen} type="button" data-tooltip-target="tooltip-fullscreen" className="p-2 text-gray-500 rounded cursor-pointer sm:ml-auto hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 4a1 1 0 011-1h4a1 1 0 010 2H6.414l2.293 2.293a1 1 0 11-1.414 1.414L5 6.414V8a1 1 0 01-2 0V4zm9 1a1 1 0 010-2h4a1 1 0 011 1v4a1 1 0 01-2 0V6.414l-2.293 2.293a1 1 0 11-1.414-1.414L13.586 5H12zm-9 7a1 1 0 012 0v1.586l2.293-2.293a1 1 0 111.414 1.414L6.414 15H8a1 1 0 010 2H4a1 1 0 01-1-1v-4zm13-1a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 010-2h1.586l-2.293-2.293a1 1 0 111.414-1.414L15 13.586V12a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Full screen</span>
                            </button>
                            <div id="tooltip-fullscreen" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                Show full screen
                                <div className="tooltip-arrow" data-popper-arrow></div>
                            </div>
                        </div>
                        <div className="px-4 py-2 h-full bg-white rounded-b-lg dark:bg-gray-800">
                            <label htmlFor="editor" className="sr-only">Publish post</label>
                            <textarea onChange={handleTextareaChange} ref={textAreaRef} id="editor" rows="8" className="block w-full h-full px-0 text-sm text-gray-800 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400" placeholder="Write an article..." required></textarea>
                        </div>
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-bold mb-2" htmlFor="numWallets">
                        Sender Amount
                    </label>
                    <input
                        className="border border-gray-300 rounded px-4 py-2 w-full"
                        type="number"
                        id="amountSenders"
                        name="amountSenders"
                        placeholder="Enter number"
                        onChange={handleAmountSenderChange}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-bold mb-2" htmlFor="numWallets">
                        Transaction rows per wallet
                    </label>
                    <input
                        className="border border-gray-300 rounded px-4 py-2 w-full"
                        type="number"
                        id="numberTransactions"
                        name="numberTransactions"
                        placeholder="Enter number"
                        onChange={handleRowPerWalletsChange}
                    />
                </div>
                <div className="flex justify-between">
                    <button
                        className="bg-[#000000] hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                        type="submit"
                        onClick={handleCreateSenderInputFile}
                    >
                        Create Bulk Sender Input File
                    </button>
                </div>

            </form >
            {
                downloadLink ? (
                    <>
                        <div className="flex justify-between">
                            <a href={downloadLink} download="List_Address.txt">
                                <button
                                    className="bg-[#000000] hover:bg-blue-600 text-white font-bold py-2 px-4 my-3 rounded"
                                    type="submit"
                                >
                                    Download Here
                                </button>
                            </a>
                        </div>
                    </>
                ) : null}

            {
                showModal ? (
                    <>
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        >
                            <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-center p-5 border-b border-solid border-slate-200 rounded-t">
                                        <AiFillWarning size={50} color="#ef4444" />
                                    </div>
                                    {/*body*/}
                                    <div className="relative p-6 flex-auto">
                                        <p className="my-4 text-slate-500 text-lg leading-relaxed">
                                            {warnStrings}
                                        </p>
                                    </div>
                                    {/*footer*/}
                                    <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                                        <button
                                            className="text-white bg-rose-500 font-bold uppercase px-6 py-2 text-sm outline-none rounded-lg hover:outline-blue-500 hover:outline-1 mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => setShowModal(false)}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null
            }
            {
                showFullScreen ? (
                    <>
                        <div className="w-full h-full mb-4 border border-gray-200 rounded-lg bg-black dark:bg-gray-700 dark:border-gray-600 flex-1 absolute">
                            <div className="flex items-center justify-between px-3 py-2 border-b dark:border-gray-600">
                                <div className="flex flex-wrap items-center divide-gray-200 sm:divide-x dark:divide-gray-600">
                                    <div className="flex flex-wrap items-center space-x-1">
                                        <button onClick={copyToClipboard} type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 512 512"><path d="M272 0H396.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H272c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48zM48 128H192v64H64V448H256V416h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48z" /></svg>
                                            <span className="sr-only">Copy</span>
                                        </button>
                                        {copySuccess}
                                        <a href={downloadLinkWallet} download="List_Generate_Wallets.txt">
                                            <button type="button" className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                <span className="sr-only">Download</span>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                                <button onClick={handleFullScreen} type="button" data-tooltip-target="tooltip-fullscreen" className="p-2 text-gray-500 rounded cursor-pointer sm:ml-auto hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 4a1 1 0 011-1h4a1 1 0 010 2H6.414l2.293 2.293a1 1 0 11-1.414 1.414L5 6.414V8a1 1 0 01-2 0V4zm9 1a1 1 0 010-2h4a1 1 0 011 1v4a1 1 0 01-2 0V6.414l-2.293 2.293a1 1 0 11-1.414-1.414L13.586 5H12zm-9 7a1 1 0 012 0v1.586l2.293-2.293a1 1 0 111.414 1.414L6.414 15H8a1 1 0 010 2H4a1 1 0 01-1-1v-4zm13-1a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 010-2h1.586l-2.293-2.293a1 1 0 111.414-1.414L15 13.586V12a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                                    <span className="sr-only">Full screen</span>
                                </button>
                                <div id="tooltip-fullscreen" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                    Show full screen
                                    <div className="tooltip-arrow" data-popper-arrow></div>
                                </div>
                            </div>
                            <div className="px-4 py-2 h-full bg-white rounded-b-lg dark:bg-gray-800">
                                <textarea onChange={handleTextareaChange} value={textAreaRefFullScreen} id="editor1" rows="8" className="block w-full h-full px-0 text-sm text-gray-800 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400" placeholder="Write an article..." required></textarea>
                            </div>
                        </div>
                    </>
                ) : null
            }
        </div >
    );
};

export default Cards;
