import React from 'react';
import Typed from 'react-typed';
import { scroller } from 'react-scroll';

const Hero = () => {

  const handleGetStarted = () => {
    scroller.scrollTo('cardsSection', {
      duration: 800,
      smooth: 'easeInOutQuart',
    });
  };

  return (
    <div className='text-white'>
      <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
        <p className='text-[#868C8F] font-bold p-2'>
            Generate MIEXS Wallets in Bulk
        </p>
        <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold md:py-6'>
          Create MIEXS wallets in bulk quickly
        </h1>
        <div className='flex justify-center items-center'>
          <p className='md:text-5xl sm:text-4xl text-xl font-bold py-4'>
              and
          </p>
          <Typed
            className='md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2'
            strings={['easily', 'conveniently', 'efficiently']}
            typeSpeed={120}
            backSpeed={140}
            loop
          />
        </div>
        <p className='md:text-2xl text-xl font-bold text-[#868C8F]'>Simplifies the process of generating a series of MIEXS wallets with just a few clicks. It's quick, easy, and convenient.</p>
        <button onClick={handleGetStarted} className='bg-[#868C8F] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-white'>Get Started</button>
      </div>
    </div>
  );
};

export default Hero;
